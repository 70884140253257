/* eslint-disable */

import { ReactComponent as Svg } from '../../assets/ImageUnloaded.svg';

export default function ImageUnloaded() {
	return (
		<div className='text-center'>
			<Svg />
		</div>
	);
}
